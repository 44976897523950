import React, { useState } from "react";
import { Carousel } from "react-responsive-carousel";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import "react-responsive-carousel/lib/styles/carousel.min.css";

import MemberApplicationForm from "../MemberApplicationForm/MemberApplicationForm.jsx";

import "./OperatorPage.scss";
const OperatorPage = (props) => {
  const [modalShown, setModalShown] = useState(false);

  const hideRequestFormToggle = () => {
    setModalShown(!modalShown);
  };

  const btnContainer = () => {
    return (
      <div className="opeartor-banner-btn-container">
        <div className="cta-buttons-wrapper">
          <div className="banner-call-to-action-buttons">
            <a
              className="book-online-now"
              href="https://assets.s3.amazonaws.com/images/front-end/new-landing/Limos-Required-Documentation.pdf"
              download
            >
              <img
                src="https://assets.s3.amazonaws.com/images/front-end/new-landing/Download.png"
                height="20px"
                alt="download"
              />
              <span className="book-online-now">
                Download Requirements Package
              </span>
            </a>
          </div>

          <div className="banner-call-to-action-buttons">
            <a href="tel:8667300524" className="banner-call-for-discount">
              <img
                src="https://assets.s3.amazonaws.com/images/front-end/new-landing/Call.png"
                height="15px"
                alt="phone"
              />
              <span className="discount-text">
                Call 866.730.0524 for Support
              </span>
            </a>
          </div>
        </div>
      </div>
    );
  };

  const specializeSection = () => {
    return (
      <div className="specialize-section">
        <h3 className="specialize-section-heading">We Also Specialize In</h3>
        <div className="specialize-section-image-container">
          <img
            src="https://assets.s3.amazonaws.com/images/front-end/new-landing/Luxury-Fleet-Vehicle.png"
            style={{ height: "170px" }}
            alt="Luxury-Fleet-Vehicle.png"
          />
        </div>
        <div className="specialize-container">
          <ul>
            <li>
              <img
                src="https://assets.s3.amazonaws.com/images/front-end/new-landing/Multi-day-events.jpg"
                alt="Multi-day-events.jpg"
              />
              <p>
                Multi-Day-Events, <br /> Multi-Vehicle Events
              </p>
            </li>

            <li>
              <img
                src="https://assets.s3.amazonaws.com/images/front-end/new-landing/Large-scale-events.jpg"
                alt="Large-scale-events.jpg"
              />
              <p>
                Large Scale Events, <br /> Conventions, Conferences
              </p>
            </li>

            <li>
              <img
                src="https://assets.s3.amazonaws.com/images/front-end/new-landing/Sporting-events.jpg"
                alt="Sporting-events.jpg"
              />
              <p>Sporting Events</p>
            </li>

            <li>
              <img
                src="https://assets.s3.amazonaws.com/images/front-end/new-landing/Commuters-schools.jpg"
                alt="Commuters-schools.jpg"
              />
              <p>Commuter & Schools</p>
            </li>

            <li>
              <img
                src="https://assets.s3.amazonaws.com/images/front-end/new-landing/Airport-transfers.jpg"
                alt="Airport-transfers.jpg"
              />
              <p>Airport Transfers</p>
            </li>

            <li>
              <img
                src="https://assets.s3.amazonaws.com/images/front-end/new-landing/Strike-transportation-disaster-relief.jpg"
                alt="Strike-transportation-disaster-relief.jpg"
              />
              <p>Strike & Disaster Relief</p>
            </li>

            <li>
              <img
                src="https://assets.s3.amazonaws.com/images/front-end/new-landing/Marathons.jpg"
                alt="Marathons.jpg"
              />
              <p>Marathons</p>
            </li>

            <li>
              <img
                src=" https://assets.s3.amazonaws.com/images/front-end/new-landing/VIP-events-team-building.jpg"
                alt="VIP-events-team-building.jpg"
              />
              <p>
                VIP Special Events <br /> & Team Building
              </p>
            </li>
          </ul>
        </div>
        <div className="request-a-quote-btn-container">
          <button
            className="request-a-quote-btn"
            onClick={hideRequestFormToggle}
          >
            <span className="request-a-quote">📋 Request a Custom Quote</span>
          </button>
        </div>
      </div>
    );
  };

  const fourFeatures = () => {
    return (
      <section className="four-features">
        <div className="container">
          <ul>
            <li>
              <img
                src="https://limos-frontend.s3.amazonaws.com/frontend/CustomerService.jpg"
                alt="CustomerService.jpg"
              />
              <p>
                Priority 24/7 <br /> Customer Support
              </p>
            </li>
            <li>
              <img
                src="https://limos-frontend.s3.amazonaws.com/frontend/BusinessTravel.jpg"
                alt="BusinessTravel.jpg"
              />
              <p>
                Worldwide Travel <br /> Solutions
              </p>
            </li>
            <li>
              <img
                src="https://limos-frontend.s3.amazonaws.com/frontend/LuxuryVehicles.jpg"
                alt="LuxuryVehicles.jpg"
              />
              <p>
                Luxury Sedans to <br /> Motorcoaches
              </p>
            </li>
            <li>
              <img
                src="https://limos-frontend.s3.amazonaws.com/frontend/professional-chauffeur.png"
                alt="professional-chauffeur.png"
              />
              <p>
                Professional Certified <br /> Chauffeurs
              </p>
            </li>
            <li>
              <img
                src="https://assets.s3.amazonaws.com/images/front-end/new-landing/DutyofCare.jpg"
                alt="DutyofCare.jpg"
              />
              <p>
                Trust and <br /> Safety
              </p>
            </li>
          </ul>
        </div>
      </section>
    );
  };

  const reviewSection = () => {
    return (
      <div className="review-main-content">
        <h2>Over 1,797+ reviews</h2>

        <div
          className="yotpo-popup-box-small yotpo-nav yotpo-nav-primary "
          role="tablist"
        >
          <ul className="">
            <li
              className="yotpo-nav-tab yotpo-active"
              data-type="site"
              role="tab"
              tabindex="0"
              aria-selected="true"
              data-content="yotpo-testimonials-site-reviews yotpo-testimonials-site-bottomline yotpo-site-reviews-tab"
              aria-controls="yotpo-testimonials-site-reviews yotpo-testimonials-site-bottomline yotpo-site-reviews-tab"
            >
              <div className="yotpo-nav-wrapper">
                <span className=""> Site Reviews </span>
              </div>
            </li>
          </ul>
        </div>

        <div className="yotpo">
          <div className="yotpo-review yotpo-regular-box">
            <div className="yotpo-header yotpo-verified-buyer ">
              <span className="yotpo-icon-profile yotpo-header-element pull-left">
                <span className="yotpo-user-letter">T</span>
                <span
                  className="yotpo-icon yotpo-icon-circle-checkmark yotpo-action-hover"
                  data-type="toggleFade"
                  data-target="yotpo-tool-tip"
                  data-target-container="yotpo-header"
                ></span>
              </span>
              <div className="yotpo-header-element ">
                <span
                  className="y-label yotpo-user-name yotpo-font-bold pull-left"
                  aria-level="3"
                >
                  Thomas D.
                </span>
                <div className="label-with-tooltip pull-left" aria-level="3">
                  <span
                    className="y-label yotpo-user-title yotpo-action-hover"
                    data-type="toggleFade"
                    data-target="yotpo-tool-tip"
                    aria-describedby="274825440"
                  >
                    Verified Buyer
                  </span>
                </div>
                <div className="yotpo-clr"></div>
                <div className="yotpo-review-stars">
                  <FontAwesomeIcon icon="star" className="star-icon" />
                  <FontAwesomeIcon icon="star" className="star-icon" />
                  <FontAwesomeIcon icon="star" className="star-icon" />
                  <FontAwesomeIcon icon="star" className="star-icon" />
                  <FontAwesomeIcon icon="star" className="star-icon" />
                </div>
                <div className="yotpo-clr"></div>
              </div>
              <div className="yotpo-header-element yotpo-header-actions ">
                <span className="y-label yotpo-review-date">03/04/21</span>
              </div>
            </div>
            <div className="yotpo-main ">
              <div
                className="content-title yotpo-font-bold"
                role="heading"
                aria-level="3"
              >
                Safe and luxurious!
              </div>
              <div className="yotpo-clr"></div>
              <div className="yotpo-review-wrapper">
                <div className="content-review">
                  Our travel too and from the airport was on time, courteous,
                  safe and comfortable.
                </div>
              </div>
              <a
                className="product-link-wrapper "
                aria-label="Product http://limos.com"
                aria-hidden="false"
                tabindex="0"
                href="https://yotpo.com/go/jN5PI8iS"
                target="_blank"
                rel="noreferrer"
              >
                <div className="y-label product-link">On https://limos.com</div>
              </a>
            </div>
            <div className="yotpo-footer ">
              <div className="footer-actions">
                <span
                  className="yotpo-action"
                  data-type="toggleFade"
                  data-target="share-review"
                  role="button"
                  tabindex="0"
                  aria-expanded="false"
                >
                  <FontAwesomeIcon icon="share-square" />
                  <span className="y-label">share</span>
                  <span className="yotpo-icon yotpo-icon-seperator"></span>
                </span>
              </div>

              <div className="yotpo-helpful" role="group">
                <span className="y-label label-helpful">
                  Was this review helpful?
                </span>
                <div
                  className="yotpo-icon-btn-small transparent-color-btn vote-btn"
                  data-type="up"
                  role="button"
                  aria-label="vote up Review by Thomas D. on  4 Mar 2021"
                  tabindex="0"
                >
                  <FontAwesomeIcon icon="thumbs-up" />
                </div>
                <span
                  className="y-label yotpo-sum vote-sum"
                  aria-live="polite"
                  aria-atomic="false"
                  data-type="up"
                >
                  2
                </span>
                <div
                  className="yotpo-icon-btn-small transparent-color-btn vote-btn"
                  data-type="down"
                  role="button"
                  aria-label="vote down Review by Thomas D. on  4 Mar 2021"
                  tabindex="0"
                >
                  {/* <FontAwesomeIcon icon="" /> */}
                </div>
                <span
                  className="y-label yotpo-sum vote-sum"
                  aria-live="polite"
                  aria-atomic="false"
                  data-type="down"
                >
                  0
                </span>
              </div>
            </div>
          </div>
        </div>

        <div className="yotpo">
          <div className="yotpo-review yotpo-regular-box">
            <div className="yotpo-header yotpo-verified-buyer ">
              <span className="yotpo-icon-profile yotpo-header-element pull-left">
                <span className="yotpo-user-letter">J</span>
                <span
                  className="yotpo-icon yotpo-icon-circle-checkmark yotpo-action-hover"
                  data-type="toggleFade"
                  data-target="yotpo-tool-tip"
                  data-target-container="yotpo-header"
                ></span>
              </span>
              <div className="yotpo-header-element ">
                <span className="y-label yotpo-user-name yotpo-font-bold pull-left">
                  Jonathan F
                </span>
                <div className="label-with-tooltip pull-left">
                  <span
                    className="y-label yotpo-user-title yotpo-action-hover"
                    data-type="toggleFade"
                    data-target="yotpo-tool-tip"
                    aria-describedby="274825440"
                  >
                    Verified Buyer
                  </span>
                </div>
                <div className="yotpo-clr"></div>
                <div className="yotpo-review-stars ">
                  <FontAwesomeIcon icon="star" className="star-icon" />
                  <FontAwesomeIcon icon="star" className="star-icon" />
                  <FontAwesomeIcon icon="star" className="star-icon" />
                  <FontAwesomeIcon icon="star" className="star-icon" />
                  <FontAwesomeIcon icon="star" className="star-icon" />
                </div>
                <div className="yotpo-clr"></div>
              </div>
              <div className="yotpo-header-element yotpo-header-actions ">
                <span className="y-label yotpo-review-date"> 02/23/21</span>
              </div>
            </div>
            <div className="yotpo-main ">
              <div
                className="content-title yotpo-font-bold"
                role="heading"
                aria-level="3"
              >
                Great!
              </div>
              <div className="yotpo-clr"></div>
              <div className="yotpo-review-wrapper">
                <div className="content-review">Great</div>
              </div>
              <a
                className="product-link-wrapper"
                tabindex="0"
                href="https://yotpo.com/go/jN5PI8iS"
                target="_blank"
                rel="noreferrer"
              >
                <div className="y-label product-link">On https://limos.com</div>
              </a>
            </div>
            <div className="yotpo-footer ">
              <div className="footer-actions">
                <span
                  className="yotpo-action"
                  data-type="toggleFade"
                  data-target="share-review"
                  role="button"
                  tabindex="0"
                  aria-expanded="false"
                >
                  <FontAwesomeIcon icon="share-square" />
                  <span className="y-label">share</span>
                  <span className="yotpo-icon yotpo-icon-seperator"></span>
                </span>
              </div>

              <div className="yotpo-helpful" role="group">
                <span className="y-label label-helpful">
                  Was this review helpful?
                </span>
                <div
                  className="yotpo-icon-btn-small transparent-color-btn vote-btn"
                  data-type="up"
                  role="button"
                  aria-label="vote up Review by Thomas D. on  4 Mar 2021"
                  tabindex="0"
                >
                  <FontAwesomeIcon icon="thumbs-up" />
                </div>
                <span
                  className="y-label yotpo-sum vote-sum"
                  aria-live="polite"
                  aria-atomic="false"
                  data-type="up"
                >
                  2
                </span>
                <div
                  className="yotpo-icon-btn-small transparent-color-btn vote-btn"
                  data-type="down"
                  role="button"
                  aria-label="vote down Review by Thomas D. on  4 Mar 2021"
                  tabindex="0"
                >
                  {/* <FontAwesomeIcon icon="" /> */}
                </div>
                <span
                  className="y-label yotpo-sum vote-sum"
                  aria-live="polite"
                  aria-atomic="false"
                  data-type="down"
                >
                  0
                </span>
              </div>
            </div>
          </div>
        </div>

        <div className="yotpo">
          <div className="yotpo-review yotpo-regular-box">
            <div className="yotpo-header yotpo-verified-buyer ">
              <span className="yotpo-icon-profile yotpo-header-element pull-left">
                <span className="yotpo-user-letter">P</span>
                <span
                  className="yotpo-icon yotpo-icon-circle-checkmark yotpo-action-hover"
                  data-type="toggleFade"
                  data-target="yotpo-tool-tip"
                  data-target-container="yotpo-header"
                ></span>
              </span>
              <div className="yotpo-header-element ">
                <span
                  className="y-label yotpo-user-name yotpo-font-bold pull-left"
                  aria-level="3"
                >
                  Patricia L.
                </span>
                <div className="label-with-tooltip pull-left" aria-level="3">
                  <span
                    className="y-label yotpo-user-title yotpo-action-hover"
                    data-type="toggleFade"
                    data-target="yotpo-tool-tip"
                    aria-describedby="274825440"
                  >
                    Verified Buyer
                  </span>
                </div>
                <div className="yotpo-clr"></div>
                <div className="yotpo-review-stars">
                  <FontAwesomeIcon icon="star" className="star-icon" />
                  <FontAwesomeIcon icon="star" className="star-icon" />
                  <FontAwesomeIcon icon="star" className="star-icon" />
                  <FontAwesomeIcon icon="star" className="star-icon" />
                  <FontAwesomeIcon icon="star" className="star-icon" />
                </div>
                <div className="yotpo-clr"></div>
              </div>
              <div className="yotpo-header-element yotpo-header-actions ">
                <span className="y-label yotpo-review-date">02/23/21</span>
              </div>
            </div>
            <div className="yotpo-main ">
              <div
                className="content-title yotpo-font-bold"
                role="heading"
                aria-level="3"
              >
                We’ve had great experiences with
              </div>
              <div className="yotpo-clr"></div>
              <div className="yotpo-review-wrapper">
                <div className="content-review">
                  We’ve had great experiences with limos.com and will continue
                  to use them in the future. Always on time and courteous
                  drivers!
                </div>
              </div>
              <a
                className="product-link-wrapper "
                aria-label="Product http://limos.com"
                aria-hidden="false"
                tabindex="0"
                href="https://yotpo.com/go/jN5PI8iS"
                target="_blank"
                rel="noreferrer"
              >
                <div className="y-label product-link">On https://limos.com</div>
              </a>
            </div>
            <div className="yotpo-footer ">
              <div className="footer-actions">
                <span
                  className="yotpo-action"
                  data-type="toggleFade"
                  data-target="share-review"
                  role="button"
                  tabindex="0"
                  aria-expanded="false"
                >
                  <FontAwesomeIcon icon="share-square" />
                  <span className="y-label">share</span>
                  <span className="yotpo-icon yotpo-icon-seperator"></span>
                </span>
              </div>

              <div className="yotpo-helpful" role="group">
                <span className="y-label label-helpful">
                  Was this review helpful?
                </span>
                <div
                  className="yotpo-icon-btn-small transparent-color-btn vote-btn"
                  data-type="up"
                  role="button"
                  tabindex="0"
                >
                  <FontAwesomeIcon icon="thumbs-up" />
                </div>
                <span
                  className="y-label yotpo-sum vote-sum"
                  aria-live="polite"
                  aria-atomic="false"
                  data-type="up"
                >
                  1
                </span>
                <div
                  className="yotpo-icon-btn-small transparent-color-btn vote-btn"
                  data-type="down"
                  role="button"
                  tabindex="0"
                >
                  {/* <FontAwesomeIcon icon="" /> */}
                </div>
                <span
                  className="y-label yotpo-sum vote-sum"
                  aria-live="polite"
                  aria-atomic="false"
                  data-type="down"
                >
                  0
                </span>
              </div>
            </div>
          </div>
        </div>

        <div className="yotpo">
          <div className="yotpo-review yotpo-regular-box">
            <div className="yotpo-header yotpo-verified-buyer ">
              <span className="yotpo-icon-profile yotpo-header-element pull-left">
                <span className="yotpo-user-letter">T</span>
                <span
                  className="yotpo-icon yotpo-icon-circle-checkmark yotpo-action-hover"
                  data-type="toggleFade"
                  data-target="yotpo-tool-tip"
                  data-target-container="yotpo-header"
                ></span>
              </span>
              <div className="yotpo-header-element ">
                <span
                  className="y-label yotpo-user-name yotpo-font-bold pull-left"
                  aria-level="3"
                >
                  Terry B.
                </span>
                <div className="label-with-tooltip pull-left" aria-level="3">
                  <span
                    className="y-label yotpo-user-title yotpo-action-hover"
                    data-type="toggleFade"
                    data-target="yotpo-tool-tip"
                    aria-describedby="274825440"
                  >
                    Verified Buyer
                  </span>
                </div>
                <div className="yotpo-clr"></div>
                <div className="yotpo-review-stars">
                  <FontAwesomeIcon icon="star" className="star-icon" />
                  <FontAwesomeIcon icon="star" className="star-icon" />
                  <FontAwesomeIcon icon="star" className="star-icon" />
                  <FontAwesomeIcon icon="star" className="star-icon" />
                  <FontAwesomeIcon icon="star" className="star-icon" />
                </div>
                <div className="yotpo-clr"></div>
              </div>
              <div className="yotpo-header-element yotpo-header-actions ">
                <span
                  className="y-label yotpo-review-date"
                  aria-label="review date 02/23/21"
                >
                  01/31/21
                </span>
              </div>
            </div>
            <div className="yotpo-main ">
              <div
                className="content-title yotpo-font-bold"
                role="heading"
                aria-level="3"
              >
                I have used this driver
              </div>
              <div className="yotpo-clr"></div>
              <div className="yotpo-review-wrapper">
                <div className="content-review">
                  I have used this driver a few times. He is very nice, always
                  on time and very personable. We hope we can continue to use
                  him for all our airport transports.
                </div>
              </div>
              <a
                className="product-link-wrapper "
                aria-label="Product http://limos.com"
                aria-hidden="false"
                tabindex="0"
                href="https://yotpo.com/go/jN5PI8iS"
                target="_blank"
                rel="noreferrer"
              >
                <div className="y-label product-link">On https://limos.com</div>
              </a>
            </div>
            <div className="yotpo-footer ">
              <div className="footer-actions">
                <span
                  className="yotpo-action"
                  data-type="toggleFade"
                  data-target="share-review"
                  role="button"
                  tabindex="0"
                  aria-expanded="false"
                >
                  <FontAwesomeIcon icon="share-square" />
                  <span className="y-label">share</span>
                  <span className="yotpo-icon yotpo-icon-seperator"></span>
                </span>
              </div>

              <div className="yotpo-helpful" role="group">
                <span className="y-label label-helpful">
                  Was this review helpful?
                </span>
                <div
                  className="yotpo-icon-btn-small transparent-color-btn vote-btn"
                  data-type="up"
                  role="button"
                  tabindex="0"
                >
                  <FontAwesomeIcon icon="thumbs-up" />
                </div>
                <span
                  className="y-label yotpo-sum vote-sum"
                  aria-live="polite"
                  aria-atomic="false"
                  data-type="up"
                >
                  2
                </span>
                <div
                  className="yotpo-icon-btn-small transparent-color-btn vote-btn"
                  data-type="down"
                  role="button"
                  tabindex="0"
                >
                  {/* <FontAwesomeIcon icon="" /> */}
                </div>
                <span
                  className="y-label yotpo-sum vote-sum"
                  aria-live="polite"
                  aria-atomic="false"
                  data-type="down"
                >
                  0
                </span>
              </div>
            </div>
          </div>
        </div>

        <div className="yotpo">
          <div className="yotpo-review yotpo-regular-box">
            <div className="yotpo-header yotpo-verified-buyer ">
              <span className="yotpo-icon-profile yotpo-header-element pull-left">
                <span className="yotpo-user-letter">C</span>
                <span
                  className="yotpo-icon yotpo-icon-circle-checkmark yotpo-action-hover"
                  data-type="toggleFade"
                  data-target="yotpo-tool-tip"
                  data-target-container="yotpo-header"
                ></span>
              </span>
              <div className="yotpo-header-element ">
                <span
                  className="y-label yotpo-user-name yotpo-font-bold pull-left"
                  aria-level="3"
                >
                  Carla L.
                </span>
                <div className="label-with-tooltip pull-left" aria-level="3">
                  <span
                    className="y-label yotpo-user-title yotpo-action-hover"
                    data-type="toggleFade"
                    data-target="yotpo-tool-tip"
                    aria-describedby="274825440"
                  >
                    Verified Buyer
                  </span>
                </div>
                <div className="yotpo-clr"></div>
                <div className="yotpo-review-stars">
                  <FontAwesomeIcon icon="star" className="star-icon" />
                  <FontAwesomeIcon icon="star" className="star-icon" />
                  <FontAwesomeIcon icon="star" className="star-icon" />
                  <FontAwesomeIcon icon="star" className="star-icon" />
                  <FontAwesomeIcon icon="star" className="star-icon" />
                </div>
                <div className="yotpo-clr"></div>
              </div>
              <div className="yotpo-header-element yotpo-header-actions ">
                <span className="y-label yotpo-review-date">01/13/21</span>
              </div>
            </div>
            <div className="yotpo-main ">
              <div
                className="content-title yotpo-font-bold"
                role="heading"
                aria-level="3"
              >
                Ephraim is the FINEST, most
              </div>
              <div className="yotpo-clr"></div>
              <div className="yotpo-review-wrapper">
                <div className="content-review">
                  Ephraim is the FINEST, most careful and kind Driver. EVER.
                  (Well, Milton is too). These two Gentlemen have been driving
                  for me for about 4 years. They both are true and trusted
                  Professionals.
                </div>
              </div>
              <a
                className="product-link-wrapper "
                aria-label="Product http://limos.com"
                aria-hidden="false"
                tabindex="0"
                href="https://yotpo.com/go/jN5PI8iS"
                target="_blank"
                rel="noreferrer"
              >
                <div className="y-label product-link">On https://limos.com</div>
              </a>
            </div>
            <div className="yotpo-footer ">
              <div className="footer-actions">
                <span
                  className="yotpo-action"
                  data-type="toggleFade"
                  data-target="share-review"
                  role="button"
                  tabindex="0"
                  aria-expanded="false"
                >
                  <FontAwesomeIcon icon="share-square" />
                  <span className="y-label">share</span>
                  <span className="yotpo-icon yotpo-icon-seperator"></span>
                </span>
              </div>

              <div className="yotpo-helpful" role="group">
                <span className="y-label label-helpful">
                  Was this review helpful?
                </span>
                <div
                  className="yotpo-icon-btn-small transparent-color-btn vote-btn"
                  data-type="up"
                  role="button"
                  tabindex="0"
                >
                  <FontAwesomeIcon icon="thumbs-up" />
                </div>
                <span
                  className="y-label yotpo-sum vote-sum"
                  aria-live="polite"
                  aria-atomic="false"
                  data-type="up"
                >
                  2
                </span>
                <div
                  className="yotpo-icon-btn-small transparent-color-btn vote-btn"
                  data-type="down"
                  role="button"
                  tabindex="0"
                >
                  {/* <FontAwesomeIcon icon="" /> */}
                </div>
                <span
                  className="y-label yotpo-sum vote-sum"
                  aria-live="polite"
                  aria-atomic="false"
                  data-type="down"
                >
                  0
                </span>
              </div>
            </div>
          </div>
        </div>

        <div className="yotpo">
          <div className="yotpo-review yotpo-regular-box">
            <div className="yotpo-header yotpo-verified-buyer ">
              <span className="yotpo-icon-profile yotpo-header-element pull-left">
                <span className="yotpo-user-letter">W</span>
                <span
                  className="yotpo-icon yotpo-icon-circle-checkmark yotpo-action-hover"
                  data-type="toggleFade"
                  data-target="yotpo-tool-tip"
                  data-target-container="yotpo-header"
                ></span>
              </span>
              <div className="yotpo-header-element ">
                <span
                  className="y-label yotpo-user-name yotpo-font-bold pull-left"
                  aria-level="3"
                >
                  Wei O.
                </span>
                <div className="label-with-tooltip pull-left" aria-level="3">
                  <span
                    className="y-label yotpo-user-title yotpo-action-hover"
                    data-type="toggleFade"
                    data-target="yotpo-tool-tip"
                    aria-describedby="274825440"
                  >
                    Verified Buyer
                  </span>
                </div>
                <div className="yotpo-clr"></div>
                <div className="yotpo-review-stars">
                  <FontAwesomeIcon icon="star" className="star-icon" />
                  <FontAwesomeIcon icon="star" className="star-icon" />
                  <FontAwesomeIcon icon="star" className="star-icon" />
                  <FontAwesomeIcon icon="star" className="star-icon" />
                  <FontAwesomeIcon icon="star" className="star-icon" />
                </div>
                <div className="yotpo-clr"></div>
              </div>
              <div className="yotpo-header-element yotpo-header-actions ">
                <span className="y-label yotpo-review-date">12/30/20</span>
              </div>
            </div>
            <div className="yotpo-main ">
              <div
                className="content-title yotpo-font-bold"
                role="heading"
                aria-level="3"
              >
                Great experience!
              </div>
              <div className="yotpo-clr"></div>
              <div className="yotpo-review-wrapper">
                <div className="content-review">
                  Allan was an amazing driver! He was patient and professional
                  all the way through. The booking process on the website was
                  also seamless and fuss-free.
                </div>
              </div>
              <a
                className="product-link-wrapper "
                aria-label="Product http://limos.com"
                aria-hidden="false"
                tabindex="0"
                href="https://yotpo.com/go/jN5PI8iS"
                target="_blank"
                rel="noreferrer"
              >
                <div className="y-label product-link">On https://limos.com</div>
              </a>
            </div>
            <div className="yotpo-footer ">
              <div className="footer-actions">
                <span
                  className="yotpo-action"
                  data-type="toggleFade"
                  data-target="share-review"
                  role="button"
                  tabindex="0"
                  aria-expanded="false"
                >
                  <FontAwesomeIcon icon="share-square" />
                  <span className="y-label">share</span>
                  <span className="yotpo-icon yotpo-icon-seperator"></span>
                </span>
              </div>

              <div className="yotpo-helpful" role="group">
                <span className="y-label label-helpful">
                  Was this review helpful?
                </span>
                <div
                  className="yotpo-icon-btn-small transparent-color-btn vote-btn"
                  data-type="up"
                  role="button"
                  tabindex="0"
                >
                  <FontAwesomeIcon icon="thumbs-up" />
                </div>
                <span
                  className="y-label yotpo-sum vote-sum"
                  aria-live="polite"
                  aria-atomic="false"
                  data-type="up"
                >
                  0
                </span>
                <div
                  className="yotpo-icon-btn-small transparent-color-btn vote-btn"
                  data-type="down"
                  role="button"
                  tabindex="0"
                >
                  {/* <FontAwesomeIcon icon="" /> */}
                </div>
                <span
                  className="y-label yotpo-sum vote-sum"
                  aria-live="polite"
                  aria-atomic="false"
                  data-type="down"
                >
                  0
                </span>
              </div>
            </div>
          </div>
        </div>

        <div className="yotpo">
          <div className="yotpo-review yotpo-regular-box">
            <div className="yotpo-header yotpo-verified-buyer ">
              <span className="yotpo-icon-profile yotpo-header-element pull-left">
                <span className="yotpo-user-letter">A</span>
                <span
                  className="yotpo-icon yotpo-icon-circle-checkmark yotpo-action-hover"
                  data-type="toggleFade"
                  data-target="yotpo-tool-tip"
                  data-target-container="yotpo-header"
                ></span>
              </span>
              <div className="yotpo-header-element ">
                <span
                  className="y-label yotpo-user-name yotpo-font-bold pull-left"
                  aria-level="3"
                >
                  Arturo P.
                </span>
                <div className="label-with-tooltip pull-left" aria-level="3">
                  <span
                    className="y-label yotpo-user-title yotpo-action-hover"
                    data-type="toggleFade"
                    data-target="yotpo-tool-tip"
                    aria-describedby="274825440"
                  >
                    Verified Buyer
                  </span>
                </div>
                <div className="yotpo-clr"></div>
                <div className="yotpo-review-stars">
                  <FontAwesomeIcon icon="star" className="star-icon" />
                  <FontAwesomeIcon icon="star" className="star-icon" />
                  <FontAwesomeIcon icon="star" className="star-icon" />
                  <FontAwesomeIcon icon="star" className="star-icon" />
                  <FontAwesomeIcon icon="star" className="star-icon" />
                </div>
                <div className="yotpo-clr"></div>
              </div>
              <div className="yotpo-header-element yotpo-header-actions ">
                <span className="y-label yotpo-review-date">12/25/20</span>
              </div>
            </div>
            <div className="yotpo-main ">
              <div
                className="content-title yotpo-font-bold"
                role="heading"
                aria-level="3"
              >
                Great service
              </div>
              <div className="yotpo-clr"></div>
              <div className="yotpo-review-wrapper">
                <div className="content-review">
                  Driver was on time and courteous. Great driving. Vehicle was
                  nice and clean. Great experience overall
                </div>
              </div>
              <a
                className="product-link-wrapper"
                aria-label="Product http://limos.com"
                aria-hidden="false"
                tabindex="0"
                href="https://yotpo.com/go/jN5PI8iS"
                target="_blank"
                rel="noreferrer"
              >
                <div className="y-label product-link">On https://limos.com</div>
              </a>
            </div>
            <div className="yotpo-footer ">
              <div className="footer-actions">
                <span
                  className="yotpo-action"
                  data-type="toggleFade"
                  data-target="share-review"
                  role="button"
                  tabindex="0"
                  aria-expanded="false"
                >
                  <FontAwesomeIcon icon="share-square" />
                  <span className="y-label">share</span>
                  <span className="yotpo-icon yotpo-icon-seperator"></span>
                </span>
              </div>

              <div className="yotpo-helpful" role="group">
                <span className="y-label label-helpful">
                  Was this review helpful?
                </span>
                <div
                  className="yotpo-icon-btn-small transparent-color-btn vote-btn"
                  data-type="up"
                  role="button"
                  tabindex="0"
                >
                  <FontAwesomeIcon icon="thumbs-up" />
                </div>
                <span
                  className="y-label yotpo-sum vote-sum"
                  aria-live="polite"
                  aria-atomic="false"
                  data-type="up"
                >
                  0
                </span>
                <div
                  className="yotpo-icon-btn-small transparent-color-btn vote-btn"
                  data-type="down"
                  role="button"
                  tabindex="0"
                >
                  {/* <FontAwesomeIcon icon="" /> */}
                </div>
                <span
                  className="y-label yotpo-sum vote-sum"
                  aria-live="polite"
                  aria-atomic="false"
                  data-type="down"
                >
                  0
                </span>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  };

  const questAnsContainer = () => {
    return (
      <div className="question-answer-container">
        <div className="question-answer-title">
          <h2>Got questions? We got answers.</h2>
        </div>
        <div className="question-answer-description-container">
          <div className="ques-ans-section">
            <p className="question-section">
              What are Limos.com requirements to join?
            </p>
            <p className="answer-section">
              Limos.com requires a minimum of $1,000,000 in automobile liability
              to join. Limos.com must also be listed as Additional Insured on
              your policy (See Required Document Download on the Application
              page.
            </p>
          </div>

          <div className="ques-ans-section">
            <p className="question-section">How do I apply with Limos.com?</p>
            <p className="answer-section">
              Visit our application page at limos.com/operators/new to fill out
              the application form.
            </p>
          </div>

          <div className="ques-ans-section">
            <p className="question-section">
              How do I get paid with Limos.com?
            </p>
            <p className="answer-section">
              Limos.com pays via Direct Deposit ACH to a checking or savings
              account of your choice.
            </p>
          </div>

          <div className="ques-ans-section">
            <p className="question-section">
              When do I get paid with Limos.com?
            </p>
            <p className="answer-section">
              Limos.com pays on a rolling 10 business day schedule. Limos.com
              makes payments weekly on Wednesdays for rides closed 10 business
              days previous.
            </p>
          </div>

          <div className="ques-ans-section">
            <p className="question-section">
              What vehicles can I add to my Limos.com Ground Control Account?
            </p>
            <p className="answer-section">
              Limos.com requires black on black vehicles 5 model years or newer.
              Exceptions on color are made for stretch limos, vans, and busses.
              Vehicles older than 5 models year may be accepted pending review.
            </p>
          </div>
        </div>
      </div>
    );
  };

  return (
    <>
      <div className="operator-page">
        <div className="slideshow-container">
          <Carousel
            autoPlay={false}
            showThumbs={false}
            showIndicators={false}
            interval={7000}
            showArrows={true}
          >
            <div className="carousel-slide-content">
              <img
                alt=""
                src="https://assets.s3.amazonaws.com/images/front-end/new-landing/limos-new-banner.jpeg"
              />
              <div className="image-shadow"></div>
            </div>
          </Carousel>
          <div className="operator-banner-text-container">
            <div className="slider-text-container">
              <div className="gcLogo">
                <img
                  alt="Limos.com"
                  src="https://assets.s3.amazonaws.com/images/front-end/new-landing/Limos.com-Ground-Control.png"
                  height={"65px"}
                />
              </div>
              <div className="slider-text">
                <p className="slider-text-container-title">No Fees to Join</p>
                <p className="slider-text-container-pre-title">
                  Seamless Onboarding
                  <br />
                  Increase Your Revenues & Bookings
                  <br />
                  Reach Customers 24x7
                  <br />
                  1,797+ Customer Reviews
                  <img
                    src="https://assets.s3.amazonaws.com/images/front-end/new-landing/Ratings-stars.png"
                    alt="star"
                    style={{ height: "25px" }}
                  />
                </p>
              </div>
            </div>
          </div>
          <div className="operator-banner-form-container">
            <div className="existing-search-form">
              <div className="form-title">
                <h3>New Member Application</h3>
              </div>
              <MemberApplicationForm></MemberApplicationForm>
            </div>

            <div className="horizontal-line"></div>

            {btnContainer()}
          </div>
        </div>
      </div>

      {questAnsContainer()}

      {specializeSection()}

      {fourFeatures()}

      {reviewSection()}

    </>
  );
};

export default OperatorPage;
