import React from "react"
import ContactAndQuoteComponent from "../ContactAndQuoteComponent/ContactAndQuoteComponent.jsx"

import "./LuxurySprinterVanRental.scss"

const LuxurySprinterVanRental = props => (
  <div className="landing-page">
    <div className="landing-header">
      <div className="landing-inner">
        <h1>Luxury Sprinter Van Rentals</h1>
        <div className="divider"></div>
        <img
          alt="A row of vehicles."
          src="https://assets.s3.amazonaws.com/images/front-end/Vehicles.png"
        />
        <h2>Luxury Sprinter Van Rentals</h2>
        <p>
          Experience unparalleled elegance and comfort with luxury sprinter van
          rental services.{" "}
        </p>
      </div>
    </div>

    <div className="landing-section flex">
      <img
        alt="Person sitting in back of car reading paper."
        src="https://assets.s3.amazonaws.com/images/front-end/Airport Transfer.jpg"
      />
      <div>
        <h2>Reliable Luxury Sprinter Van Rentals</h2>
        <p>
          We provide luxury sprinter van rentals tailored to any occasion with
          flexible multi-vehicle and multi-day options. Whether your group is
          headed to a corporate or sports event, our extensive fleet ensures
          smooth and stylish transportation.
        </p>
        <p>
          With easy call and live chat booking, you can secure multiple vehicles
          to meet all your transportation needs with precision. For multi-day
          events, our rentals allow you to customize your selection of high-end
          vehicles, making it easy to mix and match as needed. Our team works
          closely with you on pricing and planning, ensuring a seamless
          experience
        </p>
      </div>
    </div>

    <div className="landing-section flex reverse">
      <img
        alt="An auditorium full of people looking at a stage."
        src="https://assets.s3.amazonaws.com/images/front-end/Corporate Event.jpg"
      />
      <div>
        <h2>Our Luxury Sprinter Van Rental Features</h2>
        <p>
          We specialize in providing comprehensive global transportation
          management for corporate meetings and events. Our luxury sprinter vans
          offer the perfect blend of comfort and professionalism:
        </p>
        <p>
          <ul>
            <li>
              Pre-Event Consultation: We work with you to book the ideal
              transportation for your event.
            </li>
            <li>
              Airport Services: Efficient arrival and departure solutions with
              full-service greeting staff.{" "}
            </li>
            <li>
              24/7/365 Customer Support: We’re always available to assist you
              with any inquiries or adjustments.
            </li>
            <li>
              Local Shuttle Transportation: You can expect convenient and
              reliable transport between venues.
            </li>
            <li>
              Hourly Charters: Explore our flexible options for holiday parties
              and special events.
            </li>
          </ul>
        </p>
      </div>
    </div>

    <div style={{ paddingTop: 0 }}>
      <ContactAndQuoteComponent {...props} />
      <div className="landing-section" style={{ marginTop: 0 }}>
        <h2> Book Your Luxury Motor Coach Today</h2>
      </div>
    </div>

    <div className="landing-section">
      <div style={{ paddingTop: 0 }}>
        <h3>Book Your Luxury Sprinter Van Rental Today</h3>
        <h3>Why Our Luxury Sprinter Van Rentals</h3>
        <p>
          Discover the convenience and elegance our luxury sprinter van rentals
          offer. Whether you're managing a corporate event or need special event
          transportation, our services provide a sophisticated experience with
          unparalleled support and reliability.
        </p>
        <div className="flex">
          <div>
            <h3> Priority 24/7 Customer Support</h3>
            <p>
              Our dedicated support team is available around the clock to assist
              with any inquiries or last-minute adjustments. From the moment you
              book, we provide hands-on assistance, ensuring your travel plans
              stay seamless and stress-free. Reach out anytime, day or night, to
              modify reservations, address questions, or get real-time support.
            </p>
          </div>
          <div>
            <h3>Worldwide Travel Solutions</h3>
            <p>
              We offer comprehensive travel management services designed for
              global reach, making it easy to coordinate transportation
              logistics for events across the world. We handle every detail—from
              scheduling multiple pickups to managing airport transfers—allowing
              you to focus on your event. Our vast network of drivers and
              vehicles ensures reliable service, no matter the location.
            </p>
          </div>
          <div>
            <h3>Professional Certified Chauffeurs</h3>
            <p>
              Our professional chauffeurs are carefully selected for their
              experience, local knowledge, and commitment to safety and comfort.
              We go the extra mile with stringent requirements: each driver
              undergoes thorough licensing and background checks and maintains
              up-to-date compliance with local and state regulations. To further
              prioritize your safety, we require comprehensive auto liability
              insurance for every vehicle in our fleet, offering an added layer
              of security
            </p>
          </div>
          <div>
            <h3>Trust and Safety</h3>
            <p>
              We implement stringent safety protocols and regular vehicle
              inspections to ensure our clients’ well-being on every trip. We
              carefully maintain our fleet to uphold the highest standards of
              reliability, giving you confidence in your travel experience. Each
              vehicle undergoes regular checks to meet all safety and quality
              requirements, and our drivers follow strict procedures for every
              journey. Your security and peace of mind are our top priorities,
              ensuring that every ride is as safe as it is comfortable.
            </p>
          </div>
        </div>
      </div>
    </div>
    {/* <div className="landing-section">
      <div style={{ paddingTop: 0 }}>
        <h3>Why Our Luxury Motor Coaches</h3>
        <p>Luxury motor coaches offer a sophisticated travel experience that combines comfort, modern amenities, and unparalleled safety. Whether for business or leisure, our coaches provide the perfect environment to relax and stay productive with high-speed connectivity and elegant interiors.
        </p>
        <div className="flex">
          <div>
            <h4>Priority 24/7 Customer Support </h4>
            <p>
            Experience peace of mind with our 24/7 customer support. Our dedicated team is available around the clock to address any questions or concerns. Whether you need help with bookings, itinerary changes, or special requests, we're always on call to make your journey as smooth and stress-free as possible.
            </p>
          </div>
          <div>
            <h4>Worldwide Travel Solutions
            </h4>
            <p>Discover limitless travel possibilities with our worldwide travel solutions. We offer comprehensive services to cater to your global travel needs. This guarantees cross-border and domestic journeys are both convenient and stress-free. Our extensive network of partners and resources enables us to provide customized itineraries aligned with your preferences and schedule. Experience uninterrupted travel across any destination with our reliable and efficient planning.</p>
          </div>
          <div>
            <h4>Professional Certified Chauffeurs </h4>
            <p>Travel with confidence and rest assured that our professional certified chauffeurs are behind the wheel. Each chauffeur is carefully selected and trained to provide expert driving skills alongside the highest standard of service and professionalism. Our chauffeurs prioritize your safety and comfort, so you have a smooth and pleasant journey every time. With their extensive local knowledge and commitment to excellence, our chauffeurs enhance your travel experience, so it’s both enjoyable and memorable.</p>
          </div>
          <div>
            <h4>Trust and Safety</h4>
            <p>Your peace of mind is our top priority with our signature trust and safety measures. Experience secure and reliable travel experience through rigorous safety protocols and standards. We regularly inspect and maintain each of our vehicles to meet the highest safety requirements. Moreover, our drivers undergo thorough background checks and ongoing training to reflect our commitment to security and professionalism. 
            </p>
          </div>
        </div>
      </div>
    </div> */}

    <div className="landing-section flex">
      <img
        alt="A person leaving back of car with suitcase."
        src="https://assets.s3.amazonaws.com/images/front-end/Point to point.jpg"
      />
      <div>
        <h2>Use Our Luxury Sprinter Van Rentals for</h2>
        <p>
          Our luxury sprinter van rentals offer style and convenience for
          various travel needs. Whether you have multiple stops with our hourly
          service, need smooth airport transfers with flight tracking, or
          require seamless point-to-point transportation, we've got you covered.
          Enjoy the flexibility of setting your itinerary while we handle the
          driving. Experience comfort, reliability, and sophistication, all
          fully tailored to your travel requirements.
        </p>
      </div>
    </div>

    <div className="landing-section">
      <div className="flex">
        <img
          alt="People in a group dancing."
          src="https://assets.s3.amazonaws.com/images/front-end/Prom.jpg"
        />
        <div>
          <h3>Multi-Vehicle & Multi-Day Events</h3>
          <p>
            With seamless coordination and impeccable service for large
            gatherings and extended occasions, our luxury sprinter van rentals
            are perfect for multi-vehicle and multi-day events.
          </p>
          <h3>Corporate Events</h3>
          <p>
            Ensure professionalism and reliability for corporate events with our
            expert transportation solutions, tailored to accommodate meetings,
            conferences, and off-site gatherings.
          </p>
        </div>
      </div>
      <div style={{ paddingTop: 0 }}>
        <h3>Airport Transportation</h3>
        <p>
          Combining elegance and fun with safety and peace of mind for parents,
          Experience stress-free airport transfers with efficient, timely
          pickups and drop-offs. Our flight-tracking service guarantees you
          arrive comfortably on schedule.
        </p>
        <h3>One-Way & Hourly Service</h3>
        <p>
          For flexible travel, our one-way and hourly service lets you design
          your itinerary while we handle the driving. You’ll find this feature
          perfect for varying schedules or multiple destinations.
        </p>
        <h3>Prom & School Events</h3>
        <p>
          Make prom and school events memorable and safe with our luxury
          transportation. We provide a stylish and secure ride to and from
          special occasions.
        </p>

        <h3>Wedding Transportation</h3>
        <p>
          Add elegance to your wedding day with our customized transportation
          options. With our luxury sprinter vans, you can be sure guests and
          wedding parties travel in style and comfort to your venue.
        </p>

        <h3>Birthday & Anniversary Events</h3>
        <p>
          Celebrate special milestones with personalized service that lets you
          enjoy your birthday or anniversary without transportation hassles, so
          you don’t miss a moment of the fun.
        </p>

        <h3>Evenings Out on the Town</h3>
        <p>
          Enhance your night out with friends or loved ones using our luxury
          sprinter vans. We deliver a safe, sophisticated and hassle-free
          evening out on the town.
        </p>

        <h3>Concert & Sporting Event Transportation</h3>
        <p>
          Sidestep the stress of parking and traffic with our luxury sprinter
          vans. You can enjoy smooth, reliable rides to and from concerts and
          sporting events frustration-free.
        </p>

        <h3>Bachelor/Bachelorette Party Transportation</h3>
        <p>
          Party in style in our luxury sprinter vans. We offer safe, chic
          transportation for bachelor and bachelorette parties, so you can focus
          on the celebration.
        </p>

        <h3>Wine Tours</h3>
        <p>
          Relax and savor your wine tours in our luxury sprinter vans. Indulge
          in tastings without worrying about the logistics of driving.
        </p>

        <h3>Cruise Port Transportation</h3>
        <p>
          Start your cruise experience on a high note with our luxury sprinter
          van transportation. When you use our transportation services, you can
          count on comfortable and timely travel to and from cruise ports.
        </p>

        <h3>Funeral Transportation</h3>
        <p>
          Our luxury sprinter vans provide respectful and supportive
          transportation. We facilitate dignified funeral transportation
          arrangements during the most challenging times.
        </p>
      </div>

      <div style={{ paddingTop: 0 }}>
        <h2>More About Luxury Sprinter Van Rentals</h2>

        <h3>How much does a luxury sprinter van rental cost?</h3>
        <p>
          The cost of renting a luxury sprinter van varies based on factors such
          as pickup and dropoff locations, date, time, and number of passengers.
          Use our handy quote tool to search over 100,000 vehicles for the best
          deals.
        </p>

        <h3>
          How many passengers can our luxury sprinter van rentals accommodate?
        </h3>
        <p>
          Our luxury sprinter vans accommodate between 1 to 16 passengers
          comfortably, and there’s also room for luggage. This makes them ideal
          for group travel when you want everyone to ride together in style and
          comfort.
        </p>

        <h3>What amenities are included with luxury sprinter van rentals?</h3>
        <p>
          Our fleet of luxury sprinter vans often come equipped with premium
          amenities such as plush seating, climate control, high-quality audio
          and video systems, and custom lighting. Specific amenities can vary,
          so please inquire about the features available with your rental.
        </p>

        <h3>
          How are luxury sprinter van rentals different from regular van
          rentals?
        </h3>
        <p>
          The luxury sprinter vans in our fleet provide an elevated travel
          experience compared to regular van rentals. They offer enhanced
          comfort, superior amenities, and a more spacious and stylish interior,
          making them perfect for special occasions and executive travel.
        </p>
      </div>
    </div>
    <div style={{ paddingTop: 0 }}>
      <ContactAndQuoteComponent {...props} />
      <div className="landing-section" style={{ marginTop: 0 }}>
        <h2> Book Your Luxury Motor Coach Today</h2>
      </div>
    </div>
  </div>
)

export default LuxurySprinterVanRental
