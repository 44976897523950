import React, { useState, useContext } from "react"
import Navbar from "react-bootstrap/Navbar"
import { Nav } from "react-bootstrap"
import { Link, Route, Switch, useHistory } from "react-router-dom"
import Button from "react-bootstrap/Button"
import { WhiteLabelContext } from "react-whitelabel"
import { routePaths } from "../../routes/routes"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import "./Header.scss"

const Header = () => {
  const label = useContext(WhiteLabelContext)
  const [expanded, setExpanded] = useState(false)
  let history = useHistory()

  function clickContact() {
    setExpanded(false)
    history.push(routePaths.contact)
  }

  function clickHome() {
    setExpanded(false)
    history.push(routePaths.root)
  }

  function clickTerms() {
    setExpanded(false)
    history.push(routePaths.siteTerms)
  }

  function clickPrivacy() {
    setExpanded(false)
    history.push(routePaths.privacyPolicy)
  }

  function share() {
    if (window.navigator && window.navigator.share) {
      window.navigator.share({
        title: "Check out my search on Limos.com! ",
        text: "Check out my search on Limos.com!",
        url: document.location.href,
      })
    }
  }
  function toggleVehiclesMenu(menuFlag) {
    const menuWrapper = document.getElementsByClassName("vehicles-sub-menu")[0]
    if (menuFlag) {
      menuWrapper.style = "display:block"
    } else {
      menuWrapper.style = "display:none"
    }
  }
  const shareAvailable =
    typeof window !== "undefined" && !!window.navigator.share

  return (
    <aside>
      <div className="limos-navbar">
        <Navbar
          expand={false}
          expanded={expanded}
          collapseOnSelect
          fixed="top"
          className={expanded ? "navbarExpanded" : ""}
        >
          <Switch>
            <Route path={routePaths.searchResults} exact>
              <div className="shareButtonContainer">
                <a
                  href={`tel:${label.telephone}`}
                  className="linkButton shareButton btn"
                >
                  <FontAwesomeIcon icon="phone" />
                  <span className="telephone-on-medium">
                    {label.telephoneText}
                  </span>
                </a>
                {shareAvailable ? (
                  <Button onClick={share} className="shareButton">
                    <FontAwesomeIcon icon="share-square" />
                  </Button>
                ) : (
                  <></>
                )}
              </div>
            </Route>
            <Route path={routePaths.requestQuote} exact>
              <div className="shareButtonContainer">
                <a
                  href={`tel:${label.telephoneRequestAQuote}`}
                  className="linkButton shareButton btn"
                >
                  <span className="telephone-on-medium">
                    {label.telephoneTextRequestAQuote}
                  </span>
                </a>
              </div>
            </Route>
            <Route path={routePaths.requestPhoneQuote} exact>
              <div className="shareButtonContainer">
                <a
                  href={`tel:${label.telephoneRequestAQuote}`}
                  className="linkButton shareButton btn"
                >
                  <span className="telephone-on-medium">
                    {label.telephoneTextRequestAQuote}
                  </span>
                </a>
              </div>
            </Route>
            <Route
              path={[
                routePaths.root,
                routePaths.carnival,
                routePaths.services,
                routePaths.covid,
                routePaths.vehicles,
                routePaths.events,
              ]}
              exact
            >
              <div className="index-links">
                <div className="menu-each-link-wrapper">
                  <Link
                    to={routePaths.services}
                    onMouseEnter={() => toggleVehiclesMenu(false)}
                  >
                    Services
                  </Link>
                </div>
                <div className="menu-each-link-wrapper vehicles-menu">
                  <div className="menu-each-link-wrapper vehicles-menu-link">
                    <Link
                      to={routePaths.vehicles}
                      onMouseEnter={() => toggleVehiclesMenu(true)}
                    >
                      Vehicles
                    </Link>
                  </div>
                  <div
                    className="vehicles-sub-menu"
                    onMouseLeave={() => toggleVehiclesMenu(false)}
                  >
                    <div className="arrow"></div>
                    <Link
                      to={routePaths.vehicles}
                      className="fleet-menu-each-sub-item"
                    >
                      <div className="luxury-cars">
                        <div className="carImg">
                          <img
                            className="image-responsive"
                            src="https://bauersit.com/wp-content/uploads/2022/02/LuxurySedan.png"
                            alt="LuxurySedan.png"
                          />
                        </div>
                        <div className="carTitle">
                          <span>Luxury Sedans</span>
                          <p>Seats 1-4</p>
                        </div>
                      </div>
                    </Link>
                    <Link
                      to={routePaths.vehicles}
                      className="fleet-menu-each-sub-item"
                    >
                      <div className="luxury-cars">
                        <div className="carImg">
                          <img
                            className="image-responsive"
                            src="https://bauersit.com/wp-content/uploads/2022/02/LuxurySUV.png"
                            alt="LuxurySUV.png"
                          />
                        </div>
                        <div className="carTitle">
                          <span>Luxury SUVs</span>
                          <p>Seats 1-6</p>
                        </div>
                      </div>
                    </Link>
                    <Link
                      to={routePaths.vehicles}
                      className="fleet-menu-each-sub-item"
                    >
                      <div className="luxury-cars">
                        <div className="carImg">
                          <img
                            className="image-responsive"
                            src="https://bauersit.com/wp-content/uploads/2022/02/LuxurySprinter.png"
                            alt="LuxurySprinter.png"
                          />
                        </div>
                        <div className="carTitle">
                          <span>Luxury Vans</span>
                          <p>Seats 1-14</p>
                        </div>
                      </div>
                    </Link>
                    <Link
                      to={routePaths.vehicles}
                      className="fleet-menu-each-sub-item"
                    >
                      <div className="luxury-cars">
                        <div className="carImg">
                          <img
                            className="image-responsive"
                            src="https://bauersit.com/wp-content/uploads/2022/03/LuxuryShuttles.png"
                            alt="LuxuryShuttles.png"
                          />
                        </div>
                        <div className="carTitle">
                          <span>Luxury Shuttles</span>
                          <p>Seats 1-27, 1-31, 1-43</p>
                        </div>
                      </div>
                    </Link>
                    <Link
                      to={routePaths.vehicles}
                      className="fleet-menu-each-sub-item"
                    >
                      <div className="luxury-cars">
                        <div className="carImg">
                          <img
                            className="image-responsive"
                            src="https://bauersit.com/wp-content/uploads/2022/02/LuxuryMotorcoach.png"
                            alt="LuxuryMotorcoach.png"
                          />
                        </div>
                        <div className="carTitle">
                          <span>Luxury Motor Coaches</span>
                          <p>Seats 1-56</p>
                        </div>
                      </div>
                    </Link>
                  </div>
                </div>
                <div
                  className="menu-each-link-wrapper"
                  onMouseEnter={() => toggleVehiclesMenu(false)}
                >
                  <Link to={routePaths.events}>Event Request</Link>
                </div>
                <div
                  className="menu-each-link-wrapper"
                  onMouseEnter={() => toggleVehiclesMenu(false)}
                >
                  <a href="https://limos.com/my-account/reservations">
                    Corporate Accounts
                  </a>
                </div>
                <div
                  className="menu-each-link-wrapper"
                  onMouseEnter={() => toggleVehiclesMenu(false)}
                >
                  <a href="https://limos.com/signin-rsvrlookup">
                    My Existing Rides
                  </a>
                </div>
                {/* <div
                  className="menu-each-link-wrapper"
                  onMouseEnter={() => toggleVehiclesMenu(false)}
                >
                  <Link to={routePaths.covid}>COVID-19</Link>
                </div> */}

                <div
                  className="menu-each-link-wrapper"
                  onMouseEnter={() => toggleVehiclesMenu(false)}
                >
                  <div className="get-a-quote new-header-button">
                    <a href="mailto:booking@limos.com">E-Mail us</a>
                  </div>
                </div>

                <div
                  className="menu-each-link-wrapper"
                  onMouseEnter={() => toggleVehiclesMenu(false)}
                >
                  <div className="get-a-quote new-header-button">
                    <a href="tel:877-241-9318">877-241-9318</a>
                  </div>
                </div>
                <div
                  className="menu-each-link-wrapper"
                  onMouseEnter={() => toggleVehiclesMenu(false)}
                >
                  <div className="get-a-quote new-header-button">
                    <a href="">Get a Quote</a>
                  </div>
                </div>
              </div>
            </Route>
          </Switch>
          <a className="contact-icon-header" href="tel:8772419318">
            <FontAwesomeIcon icon="phone" color="#fff" />
            <span className="telephone-on-medium">{label.telephoneText}</span>
          </a>
          <Navbar.Brand className="nav-logo">
            <Link to={routePaths.root}>
              <img
                src={label.logo}
                alt="white logo limos.com text"
                style={{ height: 24 }}
              />
            </Link>
          </Navbar.Brand>

          <Navbar.Toggle
            onClick={() => setExpanded(expanded ? false : true)}
            aria-controls="basic-navbar-nav"
          >
            <FontAwesomeIcon icon="bars" />
          </Navbar.Toggle>
          <Navbar.Collapse id="basic-navbar-nav">
            <Nav className="mr-auto">
              <Nav.Link onClick={clickHome}>Home</Nav.Link>
              <Nav.Link onClick={clickContact}>Contact</Nav.Link>
              <Nav.Link href="/my-account/reservations">
                Account Sign In
              </Nav.Link>
              <Nav.Link href="/signin-rsvrlookup">My Existing Rides</Nav.Link>
              <Nav.Link href="/operators/join">Drive for us</Nav.Link>
              <Nav.Link onClick={clickTerms}>Terms</Nav.Link>
              <Nav.Link onClick={clickPrivacy}>Privacy</Nav.Link>
            </Nav>
          </Navbar.Collapse>
        </Navbar>
      </div>
    </aside>
  )
}

export default Header
