import React from "react"
import ContactAndQuoteComponent from "../ContactAndQuoteComponent/ContactAndQuoteComponent.jsx"

import "./LuxurySuvRental.scss"

const LuxurySuvRental = props => (
  <div className="landing-page">
    <div className="landing-header">
      <div className="landing-inner">
        <h1>Luxury SUV Rental</h1>
        <div className="divider"></div>
        <img
          alt="A row of vehicles."
          src="https://assets.s3.amazonaws.com/images/front-end/Vehicles.png"
        />
        <h2>Luxury SUV Rental</h2>
        <p>
          Limos.com offers premium luxury SUV rentals for corporate meetings,
          events, and personal travel. With a professional chauffeur at the
          wheel, you can sit back, relax, and enjoy a smooth, stress-free
          ride—arriving at your destination feeling refreshed and ready.
        </p>
      </div>
    </div>

    <div className="landing-section flex">
      <img
        alt="Person sitting in back of car reading paper."
        src="https://assets.s3.amazonaws.com/images/front-end/Airport Transfer.jpg"
      />
      <div>
        <h2>World-Class Luxury SUV Rental Services</h2>
        <p>
          At Limos.com, we blend comfort, style, and professionalism to provide
          an exceptional travel experience. Whether you’re attending a high-end
          corporate meeting or a special event, our luxury SUV rental services
          guarantee the utmost in comfort and class for every ride.
        </p>
      </div>
    </div>

    <div className="landing-section flex reverse">
      <img
        alt="An auditorium full of people looking at a stage."
        src="https://assets.s3.amazonaws.com/images/front-end/Corporate Event.jpg"
      />
      <div>
        <h2>Our Luxury SUV Rental Features</h2>
        <p>
          Looking for SUVs with premier vehicle amenities? Our SUVs are fitted
          with state-of-the-art technology. Here’s what you can expect with our
          luxury SUV rental services:
        </p>
        <p>
          <ul>
            <li>Professional chauffeur</li>
            <li>Room for 6 passengers</li>
            <li>Power outlets</li>
            <li>Extra storage space</li>
            <li>Custom routing</li>
            <li>Dedicated account manager</li>
          </ul>
        </p>
      </div>
    </div>

    <div style={{ paddingTop: 0 }}>
      <ContactAndQuoteComponent {...props} />
      <div className="landing-section" style={{ marginTop: 0 }}>
        <h2> Book Your Luxury Motor Coach Today</h2>
      </div>
    </div>

    <div className="landing-section">
      <div style={{ paddingTop: 0 }}>
        <h3> Why Choose Our Luxury SUV Rentals</h3>
        <p>
          Limos.com offers comprehensive transportation solutions for those
          wanting to travel in the finest luxury SUVs, making us the perfect
          choice for any occasion. Experience luxury transportation with ease
          and convenience
        </p>
        <div className="flex">
          <div>
            <h3>Premium Luxury SUVs</h3>
            <p>
              Our luxury SUVs have sleek designs and comfortable interiors. They
              can accommodate up to 6 passengers for people traveling in groups
              for any type of occasion. Our luxury SUVs also come with power
              outlets and extra storage space.
            </p>
          </div>
          <div>
            <h3>Global Travel Solutions</h3>
            <p>
              With our expansive global network, you can enjoy luxury SUV rental
              services wherever your travels take you. Whether you're heading to
              events or meetings in other countries, our rentals ensure you
              never have to worry about transportation, no matter the
              destination.
            </p>
          </div>

          <div>
            <h3>Professional Certified Chauffeurs</h3>
            <p>
              Let our team of certified professional chauffeurs handle the
              driving for you. Whether you're attending virtual meetings,
              engaging with clients, or simply unwinding, you can relax while we
              ensure you arrive at your destination safely and comfortably.
            </p>
          </div>

          <div>
            <h3>24/7 Customer Support</h3>
            <p>
              Our customer support team is available around the clock to assist
              with any questions, handle special requests, adjust bookings, and
              resolve any issues that may arise. We're here to ensure your
              experience is seamless, no matter the time.
            </p>
          </div>

          <div>
            <h3>Safe & Timely Service</h3>
            <p>
              We prioritize safety and punctuality with every ride. Each SUV is
              regularly maintained by our expert team to ensure peak
              performance, and our chauffeurs are committed to timely arrivals,
              ensuring you reach your destination on schedule, every time.
            </p>
          </div>
        </div>
      </div>
    </div>

    <div className="landing-section">
      <div style={{ paddingTop: 0 }}>
        <h2>Our Luxury SUV Rentals Are Ideal for</h2>

        <h3>Multi-Vehicle & Multi-Day Events</h3>
        <p>
          Whether you're planning a retreat, conference, or special gathering,
          we can provide multiple luxury SUVs for any occasion, even over
          extended periods. Our dedicated account managers will work with you to
          tailor transportation solutions for multi-day events, ensuring all
          your needs are met with precision and care.
        </p>

        <h3>Corporate Events</h3>
        <p>
          We offer premium transportation for corporate events, meetings, and
          conferences. Whether you're attending a local or international event,
          our luxury SUV rental services ensure you travel in comfort and style.
        </p>

        <h3>Airport Transfers</h3>
        <p>
          Experience smooth airport transfers with our round-trip luxury SUV
          rental service. We track your flights to ensure perfectly timed
          pickups and drop-offs, and provide luggage assistance for an
          effortless travel experience.
        </p>

        <h3>One-Way & Hourly Service</h3>
        <p>
          Whether you need a quick one-way ride or luxury transportation for any
          duration, our luxury SUV rental service is tailored to your needs.
          Just provide your itinerary, and we’ll ensure you reach your
          destination comfortably and on time.
        </p>

        <h3>Prom & School Events</h3>
        <p>
          Make prom night and school events unforgettable with our luxury SUVs.
          Featuring plush interiors and personal chauffeurs, our vehicles ensure
          students feel like royalty, arriving in style and making a grand
          entrance that sets the tone for an amazing evening.
        </p>

        <h3>Wedding Event Transportation</h3>
        <p>
          Enjoy luxury SUV rental for weddings and wedding-related events,
          including bachelor and bachelorette parties. Make transportation on
          your special day seamless and easy, ensuring a stress-free experience
          from start to finish.
        </p>

        <h3>Birthday & Anniversary Events</h3>
        <p>
          Make your birthday or anniversary celebration unforgettable! Hire a
          luxury SUV rental with a professional chauffeur to take you, your
          family, or friends to any location, allowing you to focus on enjoying
          your special day.
        </p>

        <h3>Evenings Out on the Town</h3>
        <p>
          Whether it's a solo date, girls' night out, or bachelor/bachelorette
          event, our luxury SUV rental provides safe and reliable
          transportation. Enjoy a night on the town with a professional
          chauffeur to ensure a stress-free and memorable experience.
        </p>

        <h3>Concert & Sporting Event Transportation</h3>
        <p>
          Skip the stress of traffic, parking, and crowded venues when heading
          to concerts or sporting events. With our luxury SUV rentals, you’ll
          arrive ready to enjoy the event and cheer for your favorite team or
          sing along to your favorite artist.
        </p>

        <h3>Wine Tours</h3>
        <p>
          Enhance your wine tour or tasting experience by booking a luxury SUV
          rental for any number of hours and vineyard locations. Enjoy a
          seamless, stylish ride as you explore the finest wines in comfort.
        </p>

        <h3>Cruise Port Transportation</h3>
        <p>
          With extra storage space, our luxury SUVs ensure you can pack
          everything you need for your cruise without the hassle of managing
          luggage. Relax and enjoy a smooth ride to the port.
        </p>

        <h3>Funeral Transportation</h3>
        <p>
          We provide respectful and reliable funeral transportation services,
          offering as many luxury SUVs as needed to accommodate your family and
          guests. Let us help ensure a smooth experience.
        </p>
      </div>
    </div>

    <div className="landing-section">
      <div style={{ paddingTop: 0 }}>
        <h2>More About Luxury SUV Rentals</h2>

        <h3>What is the best luxury SUV to rent?</h3>
        <p>
          Limos.com offers the best luxury SUVs to rent, designed to provide
          comfort, space, and style for up to six passengers. Their SUVs come
          with premium amenities like outlets for device charging, extra storage
          for luggage, and luxurious interiors, making them perfect for any
          occasion. With a professional chauffeur behind the wheel, you can
          relax without worrying about driving or finding your destination.
        </p>

        <h3>What does premium SUV rental mean?</h3>
        <p>
          A premium SUV rental refers to a luxurious, high-quality, extra-large
          SUV that offers top-tier amenities. Limos.com offers premium SUVs that
          comfortably seat up to 6 passengers and provide ample storage space,
          ensuring a spacious and comfortable ride for any occasion.
        </p>

        <h3>
          What is the difference between standard SUV and midsize SUV rental?
        </h3>
        <p>
          Key differences between a standard SUV and a midsize SUV rental are
          size, cargo space, fuel efficiency, and maneuverability. A standard
          SUV is typically slightly larger than a midsize SUV. It has more
          storage space and can easily seat 5-6 passengers. A midsize SUV rental
          is smaller, more fuel efficient, and easier to maneuver through
          smaller spaces.
        </p>

        <h3>How much is a luxury SUV rental?</h3>
        <p>
          The cost of a luxury SUV rental varies based on factors such as the
          rental company, the type of service, rental duration, number of
          pickup/drop-off locations, and other specific needs. With Limos.com,
          you get the best value for your money. Contact us today for a
          personalized quote.
        </p>

        <h3>How many people does a luxury SUV rental accommodate?</h3>
        <p>
          A luxury SUV rental comfortably accommodates 5 to 7 passengers
          depending on the specific vehicle model.
        </p>
      </div>
    </div>
    <div style={{ paddingTop: 0 }}>
      <ContactAndQuoteComponent {...props} />
      <div className="landing-section" style={{ marginTop: 0 }}>
        <h2> Book Your Luxury Motor Coach Today</h2>
      </div>
    </div>
  </div>
)

export default LuxurySuvRental
