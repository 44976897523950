import React from "react"
import ContactAndQuoteComponent from "../ContactAndQuoteComponent/ContactAndQuoteComponent.jsx"

import "./LuxuryMotorCoach.scss"

const newSpecializeSection = () => {
  return (
    <div className="new-specialize-section">
      {/* <div className="specialize-container-heading">
        <h3 className="specialize-section-heading">SPECIALIZING IN</h3>
      </div> */}
      <div className="specialize-container-slider">
        <logo-slider>
          <div className="specialize-container">
            <span>
              <img
                src="https://assets.s3.amazonaws.com/images/front-end/new-landing/PNGs/multi-day-events.png"
                alt="multi-day-events.png"
              />
              <p>Multi-Day-Events</p>
            </span>

            <span>
              <img
                src="https://assets.s3.amazonaws.com/images/front-end/new-landing/PNGs/large-events-festivals.png"
                alt="large-events-festivals.png"
              />
              <p>Large Scale Events</p>
            </span>

            {/* <span>
              <img src="https://assets.s3.amazonaws.com/images/front-end/new-landing/Sporting-events.jpg" />
              <p>Sporting Events</p>
            </span> */}

            <span>
              <img
                src="https://assets.s3.amazonaws.com/images/front-end/new-landing/PNGs/commuter-school-services.png"
                alt="commuter-school-services.png"
              />
              <p>Commuter & Schools</p>
            </span>

            <span>
              <img
                src="https://assets.s3.amazonaws.com/images/front-end/new-landing/PNGs/Airport-transfer-service.png"
                alt="Airport-transfer-service.png"
              />
              <p>Airport Transfers</p>
            </span>

            {/* <span>
              <img src="https://assets.s3.amazonaws.com/images/front-end/new-landing/Strike-transportation-disaster-relief.jpg" />
              <p>Strike & Disaster Relief</p>
            </span> */}

            {/* <span>
              <img src="https://assets.s3.amazonaws.com/images/front-end/new-landing/Marathons.jpg" />
              <p>Marathons</p>
            </span> */}

            <span>
              <img
                src="https://assets.s3.amazonaws.com/images/front-end/new-landing/PNGs/vip-exclusive-events.png"
                alt="vip-exclusive-events.png"
              />
              <p>VIP Special Events</p>
            </span>
          </div>
        </logo-slider>
      </div>
    </div>
  )
}
const LuxuryMotorCoach = props => (
  <div className="landing-page">
    <div className="landing-header">
      <div className="landing-inner">
        <h1>luxury motor coach</h1>
        <div className="divider"></div>
        <img
          alt="A row of vehicles."
          src="https://assets.s3.amazonaws.com/images/front-end/Vehicles.png"
        />
        <h2>Luxury Motor Coach Transportation</h2>
        <p>
          Our fleet caters to groups of all sizes and combines state-of-the-art
          technology, premium amenities, and a commitment to excellence.{" "}
        </p>
      </div>
    </div>

    <div className="landing-section flex reverse">
      <img
        alt="An auditorium full of people looking at a stage."
        src="https://assets.s3.amazonaws.com/images/front-end/Corporate Event.jpg"
      />
      <div>
        <h2>The Premier Luxury Transportation Experience</h2>
        <p>
          Our team excels in delivering end-to-end transportation solutions
          tailored for corporate meetings and events worldwide. Leave the
          intricacies of selecting and booking the most cost-effective,
          professional, and reliable vendors to us while you relax and enjoy the
          journey.
        </p>

        <h3>Our Full-Service Ground Transportation Solution Includes:</h3>
        <ul>
          <li>
            <strong>Pre-event Consultation:</strong> We collaborate closely with
            you to book the ideal transportation for your next event.
          </li>
          <li>
            <strong>Airport Arrival and Departure Service:</strong> Expect
            seamless and punctual airport transfers for all attendees.
          </li>
          <li>
            <strong>Full-Service Greet Staff:</strong> Our crew of professional
            staff welcomes and assists you and your guests every step of the
            way.
          </li>
          <li>
            <strong>24/7/365 Customer Care Support:</strong> We provide
            around-the-clock support to address any needs or changes, even
            last-minute.
          </li>
          <li>
            <strong>Local Shuttle Transportation:</strong> Our convenient
            shuttle services are specifically tailored to your event and any
            special requests.
          </li>
          <li>
            <strong>Hourly Charters for Events:</strong> [Continue here if more
            details are to be added].
          </li>
        </ul>

        <p>
          Trust us to design a seamless transportation experience for your
          corporate events. We exude professionalism and efficiency every step
          of the way!
        </p>
      </div>
    </div>

    <div style={{ paddingTop: 0 }}>
      <ContactAndQuoteComponent {...props} />
      <div className="landing-section" style={{ marginTop: 0 }}>
        <h2> Book Your Luxury Motor Coach Today</h2>
      </div>
    </div>

    <div className="landing-section">
      <div style={{ paddingTop: 0 }}>
        <h3>Our Luxury Motor Coach Amenities</h3>
        <p>
          Travel in superior comfort and style with luxury motor coach
          amenities. Each coach offers an array of options to enhance your
          journey with features that prioritize comfort, productivity, and
          accessibility.
        </p>
        <div className="flex">
          <div>
            <h4>Comfort</h4>
            <p>
              Relax in plush leather seating for maximum comfort and style.
              Customize your environment with adjustable lighting and climate
              control to create a setting tailored to your preferences. Enjoy
              the convenience of onboard restrooms for easy access and
              convenience throughout your journey. With these thoughtfully
              incorporated features, your travel experience will be pleasant
              from start to finish.
            </p>
          </div>
          <div>
            <h4>Productivity</h4>
            <p>
              Stay productive on the move with a luxury motor coach. Each coach
              is equipped with dedicated workstations that enable you to work
              efficiently, whether you're preparing for a meeting or catching up
              on emails. Enjoy the benefit of secure, high-speed Wi-Fi to
              maintain connectivity and access essential online resources on the
              go. Our coaches also offer collaborative workspaces, providing
              ample room for teamwork and creative brainstorming.
            </p>
          </div>
          <div>
            <h4>Accessibility</h4>
            <p>
              Choose from a selection of motor coaches designed with
              accessibility in mind to provide a smooth and inclusive travel
              experience for all passengers. Our coaches feature ADA wheelchair
              lifts, making it easier for individuals with mobility needs to
              embark and disembark. Generous bike and luggage storage options
              are also available, so you have peace of mind that your belongings
              are safely stored at all times.{" "}
            </p>
          </div>
        </div>
      </div>
    </div>
    <div className="landing-section">
      <div style={{ paddingTop: 0 }}>
        <h3>Why Our Luxury Motor Coaches</h3>
        <p>
          Luxury motor coaches offer a sophisticated travel experience that
          combines comfort, modern amenities, and unparalleled safety. Whether
          for business or leisure, our coaches provide the perfect environment
          to relax and stay productive with high-speed connectivity and elegant
          interiors.
        </p>
        <div className="flex">
          <div>
            <h4>Priority 24/7 Customer Support </h4>
            <p>
              Experience peace of mind with our 24/7 customer support. Our
              dedicated team is available around the clock to address any
              questions or concerns. Whether you need help with bookings,
              itinerary changes, or special requests, we're always on call to
              make your journey as smooth and stress-free as possible.
            </p>
          </div>
          <div>
            <h4>Worldwide Travel Solutions</h4>
            <p>
              Discover limitless travel possibilities with our worldwide travel
              solutions. We offer comprehensive services to cater to your global
              travel needs. This guarantees cross-border and domestic journeys
              are both convenient and stress-free. Our extensive network of
              partners and resources enables us to provide customized
              itineraries aligned with your preferences and schedule. Experience
              uninterrupted travel across any destination with our reliable and
              efficient planning.
            </p>
          </div>
          <div>
            <h4>Professional Certified Chauffeurs </h4>
            <p>
              Travel with confidence and rest assured that our professional
              certified chauffeurs are behind the wheel. Each chauffeur is
              carefully selected and trained to provide expert driving skills
              alongside the highest standard of service and professionalism. Our
              chauffeurs prioritize your safety and comfort, so you have a
              smooth and pleasant journey every time. With their extensive local
              knowledge and commitment to excellence, our chauffeurs enhance
              your travel experience, so it’s both enjoyable and memorable.
            </p>
          </div>
          <div>
            <h4>Trust and Safety</h4>
            <p>
              Your peace of mind is our top priority with our signature trust
              and safety measures. Experience secure and reliable travel
              experience through rigorous safety protocols and standards. We
              regularly inspect and maintain each of our vehicles to meet the
              highest safety requirements. Moreover, our drivers undergo
              thorough background checks and ongoing training to reflect our
              commitment to security and professionalism.
            </p>
          </div>
        </div>
      </div>
    </div>

    <div className="landing-section flex">
      <img
        alt="A person leaving back of car with suitcase."
        src="https://assets.s3.amazonaws.com/images/front-end/Point to point.jpg"
      />
      <div>
        <h2>To & From Airport</h2>
        <p>
          Transportation to & from airports, round trips available. Affiliates
          track flights when picking up from the airport
        </p>
        <p>
          Limos.com offers the widest variety of airport limo and car services.
          So don't schlep your luggage around in a taxi or shared shuttle when
          you can book any of our airport transportation services for close to
          the same cost. From executive limos and town cars to shuttle buses and
          airport vans, starting and ending your trip in style is easy, and
          surprisingly affordable. Happy travels!
        </p>
      </div>
    </div>

    <div className="landing-section">
      <h2>Use Our Luxury Motor Coaches for</h2>
      <div className="flex">
        <img
          alt="People in a group dancing."
          src="https://assets.s3.amazonaws.com/images/front-end/Prom.jpg"
        />
        <div>
          <h2>Multi-Vehicle & Multi-Day Events</h2>
          <p>
            For large-scale events spanning multiple days, our luxury motor
            coaches offer seamless logistics across multiple locations.
            Coordinate effortlessly with our fleet, designed to support complex
            itineraries and streamline your event transportation.
          </p>
          <h2>Corporate Events</h2>
          <p>
            Upgrade your corporate meetings and conferences with customizable
            group transportation solutions. Our luxury motor coaches provide an
            ideal blend of efficiency and professionalism, ensuring all guests
            arrive comfortably and on schedule.
          </p>
        </div>
      </div>
      <div style={{ paddingTop: 0 }}>
        <h3>Airport Transportation</h3>
        <p>
          Start and end your journey stress-free with our luxury motor coaches,
          featuring flight tracking and a range of vehicle options for timely
          and comfortable airport transfers.
        </p>

        <h3>One-Way & Hourly Service</h3>
        <p>
          Tailor your journey with our flexible one-way and hourly services to
          meet your unique needs.
        </p>

        <h3>Prom & School Events</h3>
        <p>
          Make prom night memorable with our luxury motor coach services,
          ensuring stylish and safe arrivals that provide peace of mind.
        </p>

        <h3>Wedding Transportation</h3>
        <p>
          Celebrate your special day with elegance and reliability. Our luxury
          motor coaches offer sophisticated and timely transportation for you
          and your guests, making your wedding day seamless and unforgettable.
        </p>

        <h3>Birthday & Anniversary Events</h3>
        <p>
          Celebrate in style with our diverse selection of luxury motor coaches
          that offer safe, elegant, and memorable transportation.
        </p>

        <h3>Evenings Out on the Town</h3>
        <p>
          Enjoy a night out with friends in comfort and luxury. Focus on fun
          while our expert drivers manage the transportation in our top-tier
          motor coaches.
        </p>

        <h3>Concert & Sporting Event Transportation</h3>
        <p>
          Arrive like a star at events with our luxurious transportation. We
          offer stylish options for groups of all sizes.
        </p>

        <h3>Bachelor/Bachelorette Party Transportation</h3>
        <p>
          Enhance your celebration with our equipped vehicles for entertainment
          for a safe and memorable night out.
        </p>

        <h3>Wine Tours</h3>
        <p>
          Enjoy luxurious vineyard or sight-seeing tours with our hourly
          transportation. Explore a wide selection of wines and venues with the
          benefit of comfortable, elegant transportation.
        </p>

        <h3>Cruise Port Transportation</h3>
        <p>
          Start and finish your cruise in style with our reliable and
          comfortable motor coach transportation, making the journey to and from
          the port seamless.
        </p>

        <h3>Funeral Transportation</h3>
        <p>
          We provide dignified and dependable motor coach services for funeral
          events, offering respectful support for families and guests during a
          sensitive time.
        </p>
      </div>
    </div>

    <div className="landing-section">
      <div style={{ paddingTop: 0 }}>
        <h2>More About Luxury Motor Coach Transportation</h2>

        <h3>What is a luxury motor coach?</h3>
        <p>
          A luxury motor coach is a high-end passenger vehicle designed for
          comfort and elegance. These coaches offer features like plush seating,
          advanced entertainment systems, and Wi-Fi for comfortable
          long-distance travel. Bauer’s IT offers luxury motor coaches to
          accommodate up to 56 passengers.
        </p>

        <h3>What is the difference between a bus and a motor coach?</h3>
        <p>
          Both buses and motor coaches can accommodate large groups, but there
          are differences between the two. Buses are typically designed for
          regular routes and short-distance travel with basic amenities. Motor
          coaches offer enhanced comfort and luxury features for long-distance
          travel.
        </p>

        <h3>What is the best rated motor coach?</h3>
        <p>
          The best rated motor coach can vary based on criteria such as comfort,
          amenities, and service. To determine top models, it may be helpful to
          refer to current reviews and industry ratings. At Bauer’s IT, all of
          our luxury motor coaches are equipped with state-of-the-art
          technology.
        </p>

        <h3>How much does luxury motor coach transportation cost?</h3>
        <p>
          The cost of luxury motor coach transportation can vary widely based on
          factors like distance, duration, and specific amenities provided. For
          detailed pricing, please contact us at your convenience. Our friendly
          and helpful staff is waiting to assist you.
        </p>
      </div>
    </div>
    <div style={{ paddingTop: 0 }}>
      <ContactAndQuoteComponent {...props} />
      <div className="landing-section" style={{ marginTop: 0 }}>
        <h2> Book Your Luxury Motor Coach Today</h2>
      </div>
    </div>
  </div>
)

export default LuxuryMotorCoach
